<template>
  <div>
    <!-- Features 1 Area Start -->
    <section id="features" class="features-1-area">
      <div class="container">
          <div class="row">
              <div class="col-12">
                  <div class="features-1-wrapper">

                    <div class="features-1-mobile-mockup">
                      <img :src="mockupImg" :alt="altText2">
                    </div>

                      <div class="features-1-all-box">

                        <Features1Item v-for="feature in features" :key="feature.id"
                          :title="feature.title"
                          :featureText="feature.featureText"
                          :image="feature.image"
                          :altText="feature.altText"
                        />

                      </div>
                  </div>
              </div>
          </div>
      </div>

      <!-- Background Effect Images-->
      <!-- <div class="background-shape position-absolute">
        <b-img :src="shapeImg" :img-alt="altText3"></b-img>
      </div> -->
      <!-- <div class="background-corner-bg-img position-absolute">
        <b-img :src="cornorImg" img-alt="altText4"></b-img>
      </div> -->
      <!-- Background Effect Images-->
    </section>
    <!-- Features 1 Area End -->
  </div>
</template>

<script>
import Features1Item from '@/components/home1/Features1Item.vue'

export default {
  name: 'Home2Features1',
  components:{
    Features1Item
  },
  data(){
    return{
      features: [
        {
          id: 1,
          title: 'Explore and Reserve:',
          featureText: ' Browse through a wide range of sports facilities in your area, check availability in real-time, and reserve your preferred court or field with just a few clicks.',
        },
        {
          id: 2,
          title: 'User-Friendly App:',
          featureText: 'Download our easy-to-use mobile app, available on both iOS and Android, for on-the-go reservations and updates',
        },
        {
          id: 3,
          title: 'Community Hub:',
          featureText: 'Connect with fellow sports enthusiasts and schedule games all in one application',
        }
      ],
      mockupImg: require('../../assets/img/home-2-img/features-1-mockup.png'),
      altText2: 'Features',
      shapeImg: require('../../assets/img/hero-bg-effect.png'),
      altText3: 'bg',
      cornorImg: require('../../assets/img/corner.png'),
      altText4: 'Corner'

    }
  }
}
</script>