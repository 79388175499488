<template>
  <div>
    <!-- Footer Start -->
    <footer class="copyright-area">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="copyright-wrapper d-flex justify-content-between align-items-center">
                        <div class="copyright-text">
                            <p>© {{ currentYear }} – <a href="https://mala3bhub.com/" target="_blank">Mala3bhub</a> | All Rights Reserved</p>
                        </div><!--copyright-text-->
                        <div class="terms-policy">
                            <ul>
                                <li><a href="#">Terms of Service</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div><!--terms-policy-->
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer End -->
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear(),  // Dynamically get the current year
    };
  },
};
</script>